<template>
  <div class="header">
    <HeaderHamburger
      @toggleMenu="handleToggleMenu"
      :mobile-show-menu="mobileShowMenu"
    />
    <HeaderLogo />
    <HeaderSearch />
    <!--    <HeaderCountry /> -->
    <HeaderWishlistCartUser />
    <HeaderMenu
      :mobile-show-menu="mobileShowMenu"
      @toggleMenu="handleToggleMenu"
    />
  </div>
</template>

<script>
import { ref, defineComponent } from '@nuxtjs/composition-api';
import { HeaderComponents } from '~/components/HeaderComponents';
import { useUiState } from '~/composables';

export default defineComponent({
  components: {
    ...HeaderComponents,
  },
  setup() {
    const { toggleMenuIsOpen, toggleLockBodyScroll } = useUiState();
    const mobileShowMenu = ref(false);

    const handleToggleMenu = (open = null) => {
      mobileShowMenu.value = open !== null ? open : !mobileShowMenu.value;
      toggleMenuIsOpen(mobileShowMenu.value);
      toggleLockBodyScroll(mobileShowMenu.value);
    };

    return {
      mobileShowMenu,
      handleToggleMenu,
    };
  },
});
</script>

<style lang="scss">
.header {
  display: grid;
  width: 100vw;
  height: fit-content;
  //border-bottom: 1px solid #d5d5d5;
  grid-template-columns: 4.0625rem 1fr 1fr;
  grid-template-rows: 1fr 3.375rem;
  grid-template-areas:
    'logo country wishlist-cart-user'
    'hamburger search search';
  position: sticky;
  top: 0;
  align-self: flex-start;
  @include background-general;
  padding-bottom: 0.625rem;
  z-index: 3;
  @include for-mobile {
    padding: 0.625rem;
  }
  &__logo {
    grid-area: logo;
  }
  &__hamburger {
    grid-area: hamburger;
  }
  &__menu {
    grid-area: menu;
    display: none;
  }
  &__search {
    grid-area: search;
  }
  &__country {
    grid-area: country;
  }
  &__wishlist-cart-user {
    grid-area: wishlist-cart-user;
  }
  &__top-bar {
    grid-area: top-bar;
  }
}
@include from-tablet-min {
  .header {
    height: 8.75rem;
    grid-template-columns: 14% 57% 14% 15%;
    grid-template-rows: 1fr 3.375rem;
    grid-template-areas:
      'logo search country wishlist-cart-user'
      'menu menu menu menu';
    padding-bottom: 0;
    &__logo {
      grid-area: logo;
      grid-row: 1 / 3; /* This will make the logo span both rows */
    }
    &__search {
      grid-column: 1 / 5;
    }
  }
}
</style>
